.footerContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 170px;
  background-color: #11236E;
  padding: 50px 100px;
}

.footerContainer .leftContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.footerContainer .leftContainer h3 {
  margin: 0px;
  color: white;
  letter-spacing: 3px;
  cursor: default;
  user-select: none;
}

.footerContainer .rightContainer {}

.footerContainer .leftContainer .footerLinksContainer {
  display: flex;
  align-items: center;
  list-style: none;
  color: #FFF;
  font-size: 14px;
}

.footerContainer .leftContainer .footerLinksContainer .footerLinksItem {
  margin-right: 10px;
  text-decoration: none;
}

.footerContainer .leftContainer .footerLinksContainer .footerLinksItem a {
  color: #FFF;
  text-decoration: none;
}

.footerContainer .socialMediaLinksContainer {
  display: flex;
  align-items: center;
  list-style: none;
}

.footerContainer .socialMediaLinksItem {
  margin: 0 10px;
}

@media screen and (max-width: 1024px) {
  .footerContainer {
    padding: 50px 65px;
  }
}

@media screen and (max-width: 768px) {
  .footerContainer {
    padding: 30px;
  }

  .footerContainer .leftContainer .footerLinksContainer {
    flex-direction: column;
    align-items: flex-start;
  }

  .footerContainer .leftContainer .footerLinksContainer .footerLinksItem {
    margin: 2px 0;
  }
}

@media screen and (max-width: 480px) {
  .footerContainer {
    flex-direction: column;
  }

  .footerContainer .leftContainer h3 {
    font-size: 16px;
  }

  .footerContainer .socialMediaLinksContainer {
    justify-content: flex-end;
    margin: 10px 0;
  }
}