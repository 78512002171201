.mainContainerWrap {
  background-color: #E9CB3D;
  background-image: url("../../../../assets/background_homepage.png");
  background-size: cover;
  background-position: center;
  height: calc(100% - 70px);
}

.mainContainerWrap .mainContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background: linear-gradient(300deg, rgba(37, 33, 22, 0.2), rgba(37, 33, 22, 1));
}

.mainContainerWrap .mainContainer .mainContainerContent {
  max-width: 1440px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 500px;
  margin-bottom: 50px;
}

.mainContainerWrap .mainContainer .mainContainerContent .contentTitleWithOrg {
  color: #FFF;
  line-height: 60px;
  font-size: 46px;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
}

.mainContainerWrap .mainContainer .mainContainerContent .contentTitle {
  color: #FFF;
  line-height: 70px;
  font-size: 64px;
  font-weight: 700;
  text-align: center;
}

.mainContainerWrap .mainContainer .mainContainerContent .contentTitleWithOrg span{
  display: inline-block;
  color: #E9CB3D;
}

.mainContainerWrap .mainContainer .mainContainerContent .contentDescription {
  color: #FFF;
  line-height: 35px;
  font-size: 28px;
  font-weight: 600;
  text-align: center;
}

.mainContainerWrap .mainContainer .mainContainerContent .signupButtonContainer {
  margin: 80px 0;
}

@media screen and (max-width: 1600px) {
  .mainContainerWrap .mainContainer .mainContainerContent {
    max-width: 1110px;
  }

  .mainContainerWrap .mainContainer .mainContainerContent .contentTitleWithOrg {
    font-size: 36px;
    line-height: 46px;
  }
}

@media screen and (max-width: 1024px) {
  .mainContainerWrap .mainContainer .mainContainerContent {
    max-width: 700px;
    height: 400px;
    margin-bottom: 40px;
  }

  .mainContainerWrap .mainContainer .mainContainerContent .signupButtonContainer {
    margin: 40px 0;
  }

  .mainContainerWrap .mainContainer .mainContainerContent .contentTitleWithOrg {
    line-height: 42px;
    font-size: 32px;
  }

  .mainContainerWrap .mainContainer .mainContainerContent .contentTitle {
    line-height: 48px;
    font-size: 42px;
  }

  .mainContainerWrap .mainContainer .mainContainerContent .contentDescription {
    line-height: 28px;
    font-size: 22px;
  }
}

@media screen and (max-width: 768px),
(max-height: 768px) {
  .mainContainerWrap .mainContainer .mainContainerContent {
    max-width: 480px;
    height: 300px;
    margin-bottom: 30px;
  }

  .mainContainerWrap .mainContainer .mainContainerContent .signupButtonContainer {
    margin: 40px 0;
  }

  .mainContainerWrap .mainContainer .mainContainerContent .contentTitleWithOrg {
    line-height: 34px;
    font-size: 26px;
  }

  .mainContainerWrap .mainContainer .mainContainerContent .contentTitle {
    line-height: 38px;
    font-size: 28px;
  }

  .mainContainerWrap .mainContainer .mainContainerContent .contentDescription {
    line-height: 24px;
    font-size: 18px;
  }
}

@media screen and (max-width: 480px),
(max-height: 480px) {
  .mainContainerWrap .mainContainer .mainContainerContent {
    max-width: 360px;
    height: 250px;
    margin: 0;
  }

  .mainContainerWrap .mainContainer .mainContainerContent .signupButtonContainer {
    margin: 20px 0;
  }

  .mainContainerWrap .mainContainer .mainContainerContent .contentTitleWithOrg {
    line-height: 24px;
    font-size: 18px;
  }

  .mainContainerWrap .mainContainer .mainContainerContent .contentTitle {
    line-height: 28px;
    font-size: 22px;
  }

  .mainContainerWrap .mainContainer .mainContainerContent .contentDescription {
    line-height: 22px;
    font-size: 16px;
  }
}