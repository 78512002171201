.MainPage {
  font-family: 'Montserrat', sans-serif;
}

.MainPage .mainContainer {
  min-height: calc(100vh - 240px);
  padding-top: 90px;
}

.MainPage .mainContainer .mainDesc h1 {
  font-size: 36px;
  line-height: 40px;
}

.MainPage .mainContainer .mainDesc p {
  font-size: 24px;
  color: #716F6F;
  margin-top: 10px;
  letter-spacing: 1px;
  line-height: 30px;
}

form {
  margin-top: 50px;
}

form .formRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 65%;
  margin: 25px 0;
}

.MainPage .mainContainer .ActionContainerWrap {
  position: relative;
  margin-bottom: 100px;
}

@media screen and (max-width: 768px) {
  form {
    margin-top: 25px;
  }

  form .formRow {
    width: 100%;
  }

  .MuiSelect-select {
    padding: 0 !important;
  }

  .MainPage .mainContainer {
    padding-top: 60px;
  }

  .MainPage .mainContainer .mainDesc h1 {
    font-size: 28px;
    line-height: 30px;
  }

  .MainPage .mainContainer .mainDesc p {
    font-size: 22px;
    line-height: 30px;
  }

  .MainPage .mainContainer .ActionContainerWrap {
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 480px) {
  form .formRow span {
    font-size: 16px;
  }

  form .formRow .MuiSelect-standard {
    padding: 0 !important;
  }

  .MainPage .mainContainer {
    padding-top: 40px;
  }

  .MainPage .mainContainer .mainDesc h1 {
    font-size: 18px;
    line-height: 24px;
  }

  .MainPage .mainContainer .mainDesc p {
    font-size: 16px;
    line-height: 24px;
    color: #000;
  }
}