.cardContent {
  display: flex;
  justify-content: center;
  background-color: #F8F8F8;
  padding: 35px 0 !important;
}

.cardContent .contentEmbed {
  max-width: 550px;
  min-width: 250px;
  margin: 0 15px;
}

.cardActionsWrap {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 10px 15px;
}

.cardActionsWrap .cardActionsInfo {
  display: flex;
}

.cardActionsWrap .cardActionsInfo .cardActionsInfoItem {
  margin-right: 40px;
}

.cardActionsWrap .cardActionsInfo .cardActionsInfoItem span {
  font-size: 24px;
  font-weight: bold;
  line-height: 36px;
}

.cardActionsWrap .cardActionsButtons button {
  margin: 0 15px;
  text-transform: none;
  font-size: 18px;
  border: 2px solid #1976d2;
  line-height: 24px;
}

@media screen and (max-width: 1024px) {
  .cardActionsWrap .cardActionsInfo {
    justify-content: flex-start;
  }

  .cardActionsWrap {
    flex-direction: column;
  }

  .cardActionsWrap .cardActionsButtons {
    display: flex;
    justify-content: flex-end;
    margin-top: 15px;
  }

  .cardActionsWrap .cardActionsButtons button {
    margin: 0 15px;
    font-size: 18px;
  }
}

@media screen and (max-width: 480px) {
  .cardContent {
    padding: 25px 0 !important;
  }

  .cardActionsWrap .cardActionsInfo {
    justify-content: flex-start;
  }

  .cardActionsWrap .cardActionsInfo .cardActionsInfoItem {
    margin: 5px;
  }

  .cardActionsWrap {
    flex-direction: column;
    padding: 10px 5px;
  }

  .cardActionsWrap .cardActionsInfo .cardActionsInfoItem span {
    font-size: 18px;
    line-height: 28px;
  }

  .cardActionsWrap .cardActionsInfo .cardActionsInfoItem h4 {
    font-size: 14px;
  }

  .cardActionsWrap .cardActionsButtons {
    display: flex;
    margin-top: 15px;
  }

  .cardActionsWrap .cardActionsButtons button {
    font-size: 14px;
    margin: 0 5px;
  }
}