.navbarContainer {
  width: 100%;
  background-color: #0e3499;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 70px;
  padding: 0 50px;
}

.navbarContainer .leftContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbarContainer .leftContainer .iconContainer {
  margin-right: 10px;
}

.navbarContainer .leftContainer .iconContainer img {
  user-select: none;
}

.navbarContainer .leftContainer .headingContainer .headerLink {
  margin: 0px;
  color: white;
  letter-spacing: 3px;
  cursor: default;
  user-select: none;
  font-weight: 700;
  font-size: 24px;
  text-decoration: none;
}

.navbarContainer .leftContainer .headingContainer .headerLink:hover {
  cursor: pointer;
}

@media screen and (max-width: 480px) {
  .navbarContainer {
    padding: 0 25px;
  }

  .navbarContainer .leftContainer .iconContainer {
    width: 24px;
  }

  .navbarContainer .leftContainer .headingContainer .headerLink {
    font-size: 18px;
  }
}