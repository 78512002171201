.massMediaContainer {
  display: flex;
  justify-content: space-evenly;
  height: 440px;
  background-color: #11236E;
}

.infoBlockContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  width: 300px;
  padding: 70px 0;
}

.infoBlockContainer .infoDescription {
  color: #FFF;
  font-size: 18px;
}

.infoBlockContainer .infoDescription .infoSource {
  display: block;
  color: #FFF;
  font-size: 14px;
  margin: 10px 0;
}

@media screen and (max-width: 1024px) {
  .infoBlockContainer {
    width: 230px;
  }

  .infoBlockContainer .infoDescription {
    font-size: 16px;
  }
}

@media screen and (max-width: 768px) {
  .massMediaContainer {
    flex-direction: column;
    padding: 15px;
    height: 760px;
    align-items: center;
  }

  .infoBlockContainer {
    width: 460px;
    padding: 0;
  }

  .infoBlockContainer .imgContainer img{
    width: 90px;
  }

  .infoBlockContainer .infoDescription {
    font-size: 16px;
    margin: 15px 0;
  }

  .infoBlockContainer .infoDescription .infoSource {
    font-size: 13px;
  }
}

@media screen and (max-width: 480px) {
  .massMediaContainer {
    height: 760px;
  }

  .infoBlockContainer {
    width: 300px;
  }

  .infoBlockContainer .imgContainer img{
    width: 75px;
  }

  .infoBlockContainer .infoDescription {
    font-size: 14px;
  }
}