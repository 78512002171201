@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap');

.signupBlueBtn {
  background-color: #071B52;
  color: #FFF;
  padding: 15px 20px;
  border: 2px solid transparent;
  cursor: pointer;
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  font-size: 20px;
  transition: 0.1s;
  border-radius: 2px;
}

.signupBlueBtn:hover {
  background-color: transparent;
  border: 2px solid #071B52;
  color: #071B52;
}

.signupYellowBtn {
  background-color: #E9CB3D;
  color: #000;
  padding: 15px 20px;
  border: 2px solid transparent;
  cursor: pointer;
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  font-size: 20px;
  transition: 0.1s;
  border-radius: 2px;
}

.signupYellowBtn:hover {
  background-color: transparent;
  border: 2px solid #E9CB3D;
  color: #E9CB3D;
}