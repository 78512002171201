.questionsContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 100vh;
  background-color: #0A2A82;
  padding: 200px;
}

.infoBlockContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.infoBlockContainer .infoQuestion {
  color: #FFF;
  font-size: 36px;
  font-weight: 600;
  width: 35%;
}

.infoBlockContainer .infoDescription {
  color: #FFF;
  font-size: 18px;
  width: 65%;
  padding-left: 40px;
}

@media screen and (max-width: 1024px) {
  .questionsContainer {
    padding: 100px;
  }
}

@media screen and (max-width: 768px) {
  .questionsContainer {
    padding: 40px;
  }


  .infoBlockContainer .infoQuestion {
    font-size: 26px;
    width: 30%;
  }

  .infoBlockContainer .infoDescription {
    font-size: 16px;
    width: 70%;
  }
}

@media screen and (max-width: 480px),
(max-height: 480px) {
  .questionsContainer {
    padding: 25px;
  }

  .infoBlockContainer {
    flex-direction: column;
    align-items: flex-start;
  }

  .infoBlockContainer .infoQuestion {
    font-size: 20px;
    width: 100%;
    margin-bottom: 25px;
  }

  .infoBlockContainer .infoDescription {
    font-size: 14px;
    padding: 0;
    width: 100%;
  }
}