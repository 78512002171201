.loader {
  top: 0;
  left: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #58575180;
  overflow: hidden;
  z-index: 999;
  position: absolute;
}

.importAllOrgsButton {
  border: 1.5px solid;
}

.importAllOrgsButton:hover {
  background-color: #1976d2 !important;
  color: #FFF;
  border: 1.5px solid;
}