.infoContainer {
  display: flex;
  width: 100%;
  border-radius: 5px;
  border-left: 5px solid #2C4599;
}

.infoContainer .paperWrap {
  display: flex;
  padding: 15px 0;
}

.infoContainer .paperWrap p {
  font-size: 18px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0.5px;
}

.infoContainer .paperWrap .iconInfoWrap {
  color: #2C4599;
  margin: 0 15px;
}

.infoContainer .paperWrap .iconCloseWrap button {
  padding: 0;
  margin: 0 15px;
}

@media screen and (max-width: 768px) {

  .infoContainer .paperWrap .iconInfoWrap,
  .infoContainer .paperWrap .iconCloseWrap button {
    margin: 0 5px;
  }

  .infoContainer .paperWrap p {
    font-size: 16px;
  }
}