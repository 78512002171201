.issuesInfoContainer {
  display: flex;
  justify-content: space-evenly;
  height: 100vh;
  background-color: #E9CB3D;
}

.issuesInfoContainer .issuesInfoLeftContainer {
  width: 460px;
  padding: 90px 80px;
}

.issuesInfoContainer .issuesInfoRightContainer {
  width: calc(100% - 460px);
  background-image: url("../../../../assets/issues_info_background.png");
  background-size: cover;
}

.issuesInfoContainer .issuesInfoLeftContainer .issuesDescription {
  font-size: 36px;
}

.issuesInfoContainer .issuesInfoRightContainer .issuesInfoRightContainerWrap {
  height: 100%;
  background: linear-gradient(300deg, rgba(22, 30, 39, 0.2), rgba(22, 30, 39, 0.9));
}

.issuesInfoContainer .issuesInfoRightContainer .issuesInfoRightContainerWrap .tagList {
  color: #FFF;
  font-size: 36px;
  list-style: none;
  padding: 50px;
}

.issuesInfoContainer .issuesInfoRightContainer .issuesInfoRightContainerWrap .tagListItem {
  margin: 50px 0;
}

@media screen and (max-width: 1024px),
(max-height: 820px) {
  .issuesInfoContainer .issuesInfoLeftContainer {
    width: 300px;
    padding: 70px 60px;
  }

  .issuesInfoContainer .issuesInfoRightContainer {
    width: calc(100% - 300px);
  }

  .issuesInfoContainer .issuesInfoLeftContainer .issuesDescription {
    font-size: 28px;
  }

  .issuesInfoContainer .issuesInfoRightContainer .issuesInfoRightContainerWrap .tagList {
    font-size: 28px;
    padding: 40px;
  }
}

@media screen and (max-width: 768px),
(max-height: 768px) {
  .issuesInfoContainer .issuesInfoLeftContainer {
    width: 200px;
    padding: 70px 20px;
  }

  .issuesInfoContainer .issuesInfoRightContainer {
    width: calc(100% - 200px);
  }

  .issuesInfoContainer .issuesInfoLeftContainer .issuesDescription {
    font-size: 24px;
  }

  .issuesInfoContainer .issuesInfoRightContainer .issuesInfoRightContainerWrap .tagList {
    font-size: 24px;
    padding: 15px 30px;
  }

  .issuesInfoContainer .issuesInfoRightContainer .issuesInfoRightContainerWrap .tagListItem {
    margin: 30px 0;
  }
}

@media screen and (max-width: 480px),
(max-height: 480px) {
  .issuesInfoContainer .issuesInfoLeftContainer {
    width: 150px;
    padding: 40px 15px;
  }

  .issuesInfoContainer .issuesInfoRightContainer {
    width: calc(100% - 150px);
  }

  .issuesInfoContainer .issuesInfoLeftContainer .issuesDescription {
    font-size: 18px;
  }

  .issuesInfoContainer .issuesInfoRightContainer .issuesInfoRightContainerWrap .tagList {
    font-size: 18px;
    padding: 5px 30px;
  }

  .issuesInfoContainer .issuesInfoRightContainer .issuesInfoRightContainerWrap .tagListItem {
    margin: 25px 0;
  }
}