.statInfoContainer {
  display: flex;
  justify-content: space-evenly;
  height: 440px;
  background-color: #0A2A82;
}

.infoBlockContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  width: 375px;
  margin-bottom: 50px;
}

.infoBlockContainer .infoCount {
  color: #FFF;
  font-size: 64px;
  margin: 15px 0;
}

.infoBlockContainer .infoDescription {
  color: #FFF;
  font-size: 28px;
}

@media screen and (max-width: 1024px) {
  .infoBlockContainer {
    width: 325px;
  }

  .infoBlockContainer .infoCount {
    font-size: 56px;
  }

  .infoBlockContainer .infoDescription {
    font-size: 24px;
  }
}

@media screen and (max-width: 768px) {
  .statInfoContainer {
    height: 350px;
  }

  .infoBlockContainer {
    width: 250px;
  }

  .infoBlockContainer .infoCount {
    font-size: 48px;
  }

  .infoBlockContainer .infoDescription {
    font-size: 18px;
  }
}

@media screen and (max-width: 480px) {
  .statInfoContainer {
    flex-direction: column;
    padding: 0 25px;
  }

  .infoBlockContainer {
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin: 0;
  }

  .infoBlockContainer .infoCount {
    font-size: 36px;
    margin-right: 15px;
  }

  .infoBlockContainer .infoDescription {
    font-size: 18px;
    text-align: left;
  }
}