.loaderWrap {
  top: 0;
  left: 0;
  bottom: 0;
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #58575180;
  overflow: hidden;
  z-index: 999;
  position: fixed;
}

.loaderContainer {
  display: flex;
  padding: 25px;
  background-color: #0000006e;
  border-radius: 20px;
}

@media screen and (max-width: 480px) {
  .loaderContainer {
    padding: 15px;
    border-radius: 20px;
  }
}