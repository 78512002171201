.loginBtn {
  background-color: transparent;
  color: white;
  border: 2px solid white;
  font-size: 15px;
  padding: 7px 16px 7px 16px;
  text-align: center;
  cursor: pointer;
  font-weight: bold;
}
.loginBtn:hover {
  background-color: white;
  border: 2px solid #0e3499;
  color: #0e3499;
  font-weight: bold;
}
