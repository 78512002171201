.joinTheChoirContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 450px;
  background-color: #E9CB3D;
}

.joinTheChoirContainer .headerText {
  font-size: 40px;
  margin: 30px 0;
}

.joinTheChoirContainer .buttonContainer {
  margin: 30px 0;
}

@media screen and (max-width: 480px),
(max-height: 480px) {
  .joinTheChoirContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 300px;
    background-color: #E9CB3D;
  }

  .joinTheChoirContainer .headerText {
    font-size: 32px;
  }
}