.tweetsImportForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 450px;
  margin-top: 15px;
  margin-bottom: 15px;
}

.formControl {
  width: 250px;
}

.resultContainer {
  display: flex;
  justify-content: center;
  width: 450px;
  padding: 50px 0;
}

.resultContainerRowImported {
  display: flex;
  align-items: center;
  color: green;
  margin: 0 15px;
}

.resultContainerRowDuplicate {
  display: flex;
  align-items: center;
  color: orange;
  margin: 0 15px;
}

.resultContainerRowError {
  display: flex;
  align-items: center;
  color: red;
  margin: 0 15px;
}

.resultLabel {
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
}

.resultValue {
  font-size: 24px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 23px;
  margin: 0 5px;
}

@media screen and (max-width: 768px) {
  .tweetsImportForm {
    width: 350px;
  }
}

@media screen and (max-width: 480px) {
  .tweetsImportForm {
    width: 250px;
  }

  .formControl {
    width: 200px;
  }
}